import { useMemo } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../../assets/css/components/controls/PhoneInput.scss';
import { testPhone } from '../../utils';
import { TextInputProps } from './TextInput';

export type PhoneInputProps = Omit<TextInputProps, 'type' | 'placeholder'> & {
  action?: { label: string; onClick: ( phone: string ) => void };
};

export const PhoneInput = ( {
  onChange,
  value,
  className,
  title,
  action,
  error,
  onBlur,
  name
}: PhoneInputProps ) => {
  const formattedValue = value;

  const middlewareOnChange = ( value: string ) => {
    const formattedValue = `+${value}`;
    onChange( formattedValue );
  };

  const onActionClick =
    action &&
    ( () => {
      if ( testPhone( value ) ) action.onClick( value );
    } );

  const containerClassName = useMemo(
    () =>
      [
        'text-input phone',
        title ? 'labeled' : undefined,
        error ? 'error' : undefined,
        className
      ].join( ' ' ),
    [ error, className, title ]
  );

  return (
    <div className='text-input-wrapper'>
      <ReactPhoneInput
        onChange={middlewareOnChange}
        value={formattedValue}
        placeholder={'+1234567890'}
        country='sg'
        inputClass='phone'
        containerClass={containerClassName}
        buttonClass='country-button'
        onBlur={onBlur}
        inputProps={{ name }}
        specialLabel={title}
      />
      {action && (
        <div
          className='action'
          onClick={onActionClick}
        >
          {action.label}
        </div>
      )}
      {error && <div className='error'>{error}</div>}
    </div>
  );
};

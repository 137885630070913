import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { authService } from '.';
import { Store } from '../state';
import { authActions } from '../state/slices/authSlice';
import { handleDates } from '../utils';

// export const baseUrl = 'http://192.168.1.178:8443';
export const baseUrl = 'https://api.crewww.io/dev';

let store: Store;

export const servicesInjectStore = ( _store: Store ) => {
  store = _store;
};

export const authorizedAxiosInstance = axios.create( {
  baseURL: baseUrl,
  transformRequest: [
    ( data, headers ) => {
      if ( headers )
        headers['Authorization'] = `Bearer ${store.getState().auth.accessToken}`;
      return data;
    },
    ...( axios.defaults.transformRequest as [] )
  ]
} );

authorizedAxiosInstance.interceptors.response.use(
  async response => {
    store.dispatch( authActions.setIsAuthorized( true ) );
    return response;
  },
  async ( error: AxiosError & { config: { _retry?: boolean } } ) => {
    const originalRequest = error.config;
    if ( error.response?.status === 401 ) {
      if ( !originalRequest._retry ) {
        originalRequest._retry = true;
        await authService.refreshMutex();
        return authorizedAxiosInstance( originalRequest );
      } else {
        store.dispatch( authActions.setIsAuthorized( false ) );
      }
    } else {
      throw error;
    }
  }
);

authorizedAxiosInstance.interceptors.response.use( originalResponse => {
  if ( originalResponse?.data ) handleDates( originalResponse.data );
  return originalResponse;
} );

export const axiosBaseQuery: BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
  },
  unknown,
  unknown
> = async ( { url, method = 'GET', data, params } ) => {
  try {
    const finalUrl = /^https?:\/\//.test( url ) ? url : baseUrl + url;
    const result = await authorizedAxiosInstance( { url: finalUrl, method, data, params } );
    return { data: result.data };
  } catch ( axiosError ) {
    const err = axiosError as AxiosError;
    return {
      error: { status: err.response?.status, data: err.response?.data }
    };
  }
};

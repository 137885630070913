import { Formik } from 'formik';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes, routesPaths } from '../../navigation';
import { RegisterFormFields } from '../../services';
import { registrationService } from '../../services/registrationService';
import { useTypedSelector } from '../../state';
import { authActions } from '../../state/slices/authSlice';
import { otpActions } from '../../state/slices/otpService';
import { getRegistrationInitialValues, registrationValidationSchema } from '../../utils';
import {
  BirthDateField,
  CheckBoxField,
  DropDownField,
  OTPField,
  PhoneField,
  Submit,
  TextField
} from '../forms';
import { PasswordField } from '../forms/PasswordField';
import { LabeledField, ScreenTitle } from '../ui';

const initialValues: RegisterFormFields = getRegistrationInitialValues();

export const RegisterScreen = () => {
  const registerState = useTypedSelector( state => state.register );
  const otpState = useTypedSelector( state => state.otp );
  const [ otpSent, setOTPSent ] = useState( false );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = ( values: RegisterFormFields ) => {
    registrationService.register( values );
  };

  useEffect( () => {
    if ( otpState.error && typeof otpState.error === 'string' ) {
      toast.error( otpState.error );
      dispatch( otpActions.setError( undefined ) );
    }
    if ( otpSent && otpState.success ) {
      toast.success( 'One time password has been sent to your phone number' );
      dispatch( otpActions.setSuccess( undefined ) );
    }
  }, [ otpState.error, otpSent, otpState.success ] );

  useEffect( () => {
    if ( registerState.success ) {
      toast.success( 'Registered successfully' );
      navigate( routes.auth.login.path );
      dispatch( authActions.setFirstLogin( true ) );
    } else if ( registerState.error && typeof registerState.error === 'string' ) {
      toast.error( registerState.error );
    }
  }, [ registerState.success ] );

  const sendOTP = async ( phone: string ) => {
    const sent = await registrationService.sendOTP( phone, 'register' );
    setOTPSent( !!sent );
  };

  return (
    <>
      <ScreenTitle title='Let’s get started' />
      <Formik<RegisterFormFields>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={registrationValidationSchema}
        validateOnMount={true}
      >
        {( { handleSubmit, errors, values } ) => (
          <form onSubmit={handleSubmit}>
            <TextField
              name='name'
              title='Name'
              placeholder='Name'
            />
            <LabeledField label='Date of birth'>
              <BirthDateField />
            </LabeledField>
            <DropDownField
              name='gender'
              title='Gender'
              placeholder='Gender'
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'Other', value: 'other' }
              ]}
            />
            <PhoneField
              name='phone'
              title='Phone Number'
              action={
                !errors.phone && !otpSent
                  ? {
                      label: 'Send OTP',
                      onClick: sendOTP
                    }
                  : undefined
              }
            />
            <OTPField
              name='otp'
              title='Fill your OTP code below'
              action={
                !errors.phone && otpSent
                  ? {
                      label: 'Did not get a code?',
                      onClick: sendOTP.bind( null, values.phone ),
                      buttonText: 'Resend OTP',
                      lastSend: DateTime.now().plus( { seconds: 60 } )
                    }
                  : undefined
              }
              disabled={!otpSent}
              shouldFocus={otpSent}
            />
            <PasswordField
              name='password'
              title='Password'
              placeholder='Password'
            />
            <PasswordField
              name='confirmPassword'
              title='Confirm Password'
              placeholder='Confirm Password'
            />
            <CheckBoxField
              name='termsAgreed'
              label={
                <p>
                  I agree to Crewww’s{' '}
                  <a
                    href={routesPaths.terms}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Terms and Conditions
                  </a>
                  , and agree to being over 18 years of age
                </p>
              }
            />
            <CheckBoxField
              name='emailMarketing'
              label='I want to receive email marketing communications from Crewww'
            />
            <Submit text='Sign Up' />
          </form>
        )}
      </Formik>
    </>
  );
};

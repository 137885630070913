import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import ReactOTPInput from 'react-otp-input';
import '../../assets/css/components/controls/OTPInput.css';
import { CountDown } from '../ui/views/CountDown';

export interface OTPInputProps {
  name: string;
  value: string;
  onChange: ( otp: string ) => void;
  title?: string;
  action?: { label: string; onClick: () => void; buttonText: string; lastSend: DateTime };
  disabled?: boolean;
  shouldFocus?: boolean;
}

export const OTPInput = ( {
  value,
  onChange,
  title,
  action,
  disabled,
  shouldFocus
}: OTPInputProps ) => {
  const [ showButton, setShowButton ] = useState( false );
  const [ canPerformAction, setCanPerformAction ] = useState( false );
  const [ otpAutofill, setOtpAutofill ] = useState<string | undefined>();

  const onActionLabelClick = () => setShowButton( !showButton );
  const onActionClick = canPerformAction
    ? () => {
        action?.onClick();
        setShowButton( false );
      }
    : undefined;

  const onCountDownReached = () => {
    setCanPerformAction( true );
  };

  useEffect( () => {
    if ( 'OTPCredential' in window && !otpAutofill ) {
      const ac = new AbortController();
      navigator.credentials
        .get( {
          signal: ac.signal,
          otp: { transport: [ 'sms' ] }
        } as never )
        .then( res => {
          const otp = res as unknown as { code: string };
          if ( otp.code ) setOtpAutofill( otp.code );
        } )
        .catch( err => {
          console.log( err );
        } );
      return () => {
        ac.abort();
      };
    }
  }, [ setOtpAutofill ] );

  useEffect( () => {
    if ( otpAutofill ) {
      onChange( otpAutofill );
      setOtpAutofill( undefined );
    }
  }, [ otpAutofill ] );

  return (
    <div className={[ 'otp-input', disabled ? 'disabled' : undefined ].join( ' ' )}>
      {title && <label>{title}</label>}

      <ReactOTPInput
        onChange={onChange}
        value={value}
        renderInput={props => (
          <input
            {...props}
            autoComplete='one-time-code'
            onPaste={e => {
              props.onPaste( e );
            }}
            onKeyDown={() => null}
          />
        )}
        numInputs={6}
        inputType='number'
        containerStyle='inputs'
        shouldAutoFocus={shouldFocus}
      />

      {action && (
        <div className='action'>
          <span onClick={onActionLabelClick}>{action.label}</span>
          {showButton && (
            <button
              className='button'
              onClick={onActionClick}
              disabled={!canPerformAction}
            >
              <CountDown
                target={action.lastSend}
                reachedLabel={action.buttonText}
                onReached={onCountDownReached}
              />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

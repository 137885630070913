import axios from 'axios';
import { baseUrl, OTPActions, RegisterFormFields } from '.';
import { store } from '../state';
import { otpActions } from '../state/slices/otpService';
import { registerActions } from '../state/slices/registerSlice';
import { facebookActions, googleActions } from '../state/slices/thirdParty';
import { FacebookRegisterData, GoogleRegisterData, TokensPair } from './types';

const client = axios.create( {
  baseURL: baseUrl
} );

async function register ( info: RegisterFormFields ) {
  try {
    const { status } = await client.post( '/auth/register', { ...info, confirmPassword: undefined } );
    if ( status === 200 ) store.dispatch( registerActions.setSuccess( true ) );
  } catch ( error ) {
    processErrorRegister( error );
  }
}

async function sendOTP ( phone: string, action: OTPActions ) {
  try {
    const { status } = await client.post( '/auth/otp', { phone, action } );
    if ( status === 200 ) store.dispatch( otpActions.setSuccess( true ) );
    return status === 200;
  } catch ( error ) {
    processErrorOTP( error );
    return false;
  }
}
function processErrorRegister ( error: unknown ) {
  let errorMessage = error;
  if ( axios.isAxiosError( error ) ) errorMessage = error.response?.data;
  store.dispatch( registerActions.setError( errorMessage as string | object ) );
}
function processErrorOTP ( error: unknown ) {
  let errorMessage = error;
  console.log( { error } );
  if ( axios.isAxiosError( error ) ) {
    if ( error.response?.data.message )
      errorMessage = error.response.data.message;
    else
      errorMessage = error.response?.data;
  }
  store.dispatch( otpActions.setError( errorMessage as string | object ) );
}

async function registerWithFacebook ( registrationData: FacebookRegisterData ) {
  try {
    const { status, data } = await client.post( '/socials/facebook/register', registrationData );
    store.dispatch( facebookActions.setRegistrationSuccess( status === 200 ) );
    if ( status === 200 ) return data as TokensPair;
  } catch ( err ) {
    console.log( err );
  }
}

async function registerWithGoogle ( registrationData: GoogleRegisterData ) {
  try {
    const { status, data } = await client.post( '/socials/google/register', registrationData );
    store.dispatch( googleActions.setRegistrationSuccess( status === 200 ) );
    if ( status === 200 ) return data as TokensPair;
  } catch ( err ) {
    console.log( err );
  }
}


export const registrationService = {
  register,
  sendOTP,
  registerWithFacebook,
  registerWithGoogle
};
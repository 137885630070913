import { DateTime } from 'luxon';
import { CurrentUser, Event, EventSingle, User } from '../services';

export const generateMapsLocationURL = ( event: Pick<Event, 'location'> | undefined ) => {
  if ( !event ) return undefined;
  const url = new URL( 'https://www.google.com/maps/search/' );
  url.searchParams.append( 'api', '1' );
  url.searchParams.append( 'query', `${event.location.lat},${event.location.lng}` );
  return url.toString();
};

export const isEventMember = ( event: EventSingle | undefined, user: User | undefined ) => {
  if ( !event || !user ) return false;
  if ( event.byoc ) {
    return !!(
      event.byoc.authorCrew.members.find( item => item._id === user._id ) ||
      event.byoc.joinedCrew?.members.find( item => item._id === user._id )
    );
  }
  return !!event.members.find( member => member._id === user._id );
};

export const isEventAuthor = ( event: EventSingle | undefined, user: User | undefined ) => {
  if ( !event || !user ) return false;
  return event.author._id === user._id;
};
export const canUserJoinEvent = (
  event: EventSingle | undefined,
  user: CurrentUser | undefined
) => {
  const byoc = event?.byoc as EventSingle['byoc'];
  if ( byoc?.authorCrew ) {
    const { members, invitedPhones } = byoc.authorCrew;
    if ( !user ) return false;
    if ( ( members as unknown as string[] ).includes( user._id ) ) return false;
    if ( ( invitedPhones as unknown as string[] ).includes( user.phone.replace( '+', '' ) ) )
      return false;
    return byoc.targetCrewGender === user.gender;
  }
  if ( event?.filters ) {
    if ( event.filters.age ) {
      const { min, max } = event.filters.age;
      const userAge = user?.birthDate
        ? Math.floor( -DateTime.fromJSDate( user.birthDate.date ).diffNow( 'years' ).years )
        : user?.age || 0;
      if ( userAge > max || userAge < min ) return false;
    }
    if ( event.filters.gender ) {
      if ( event.author.gender !== user?.gender ) return false;
    }
  }
  return (
    user &&
    event &&
    event.author._id !== user._id &&
    !isEventMember( event, user ) &&
    event.members.length < event.maxMembers
  );
};

export const getEventButtonText = (
  event: EventSingle | undefined,
  user: User | undefined
) => {
  if ( !user || !event ) return '';
  if ( event.author._id === user._id ) {
    return 'You are creator';
  } else if ( isEventMember( event, user ) ) {
    return 'Joined';
  } else if ( event.members.length >= event.maxMembers ) {
    return 'Maximum members';
  } else {
    return 'Join';
  }
};

export const getUserAboutExcerpt = ( about: string, length = 70, end = '...' ) => {
  const split = about.split( ' ' );
  let result = '';
  for ( const word of split ) {
    const tmp = [ result, word ].join( ' ' );
    if ( tmp.length < length ) result = tmp;
    else break;
  }
  return result + end;
};

export const getEventUrlSignature = ( event: Pick<Event, '_id' | 'title'> ) => {
  return `${event.title.replace( /[^a-z\d]+/gi, '' )}_${event._id}`;
};

export const getEventIdFromSignature = ( signature: string ) => {
  const split = signature.split( '_' );
  return split[1];
};

export const getEventUrl = ( event: Pick<Event, '_id' | 'title'> ) =>
  `/event/${getEventUrlSignature( event )}`;

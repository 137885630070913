import { useFormikContext } from 'formik';
import { PhoneInput, PhoneInputProps } from '../controls';

export type PhoneFieldProps = Omit<
  PhoneInputProps,
  'value' | 'onChange' | 'defaultValue'
>;

export const PhoneField = ( props: PhoneFieldProps ) => {
  const { values, initialValues, handleChange, errors, touched, handleBlur } =
    useFormikContext();

  const error = ( errors as Record<string, string | undefined> )[props.name];
  const isTouched = ( touched as Record<string, boolean | undefined> )[props.name];

  console.log( { error, value: ( values as Record<string, string> )[props.name as string] } );

  return (
    <PhoneInput
      {...props}
      value={( values as Record<string, string> )[props.name]}
      onChange={handleChange( props.name )}
      defaultValue={( initialValues as Record<string, string> )[props.name]}
      error={isTouched ? error : undefined}
      onBlur={handleBlur( props.name )}
    />
  );
};

import { ReactNode, useMemo } from 'react';
import '../../assets/css/components/controls/TextInput.scss';

export interface TextInputProps {
  name: string;
  onChange: ( value: string ) => void;
  value: string;
  defaultValue?: string;
  type?: 'text' | 'email' | 'password' | 'tel' | 'number';
  className?: string;
  title?: string;
  placeholder?: string;
  children?: ReactNode;
  min?: number;
  max?: number;
  error?: string;
  onBlur?: ( e: unknown ) => void;
  tip?: string;
  disabled?: boolean;
}

export const TextInput = ( {
  name,
  value,
  onChange,
  type = 'text',
  className,
  title,
  placeholder,
  children,
  min,
  max,
  error,
  onBlur,
  tip,
  disabled
}: TextInputProps ) => {
  const inputNumberOnChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    if ( +event.target.value < 0 ) return;
    if ( max && +event.target.value > max ) return;
    onChange( event.target.value );
  };

  const inputOnChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    onChange( event.target.value );
  };

  const containerClassName = useMemo(
    () =>
      [
        'text-input',
        title ? 'labeled' : undefined,
        error ? 'error' : undefined,
        disabled ? 'disabled' : undefined,
        className
      ].join( ' ' ),
    [ error, className, title ]
  );

  return (
    <div className={containerClassName}>
      {title && <label htmlFor={name}>{title}</label>}
      <input
        type={type}
        value={value}
        onChange={type === 'number' ? inputNumberOnChange : inputOnChange}
        placeholder={placeholder}
        name={name}
        id={name}
        min={min}
        max={max}
        onBlur={onBlur}
        disabled={disabled}
      />
      {children}
      {error && <div className='error'>{error}</div>}
      {tip && <div className='tip'>{tip}</div>}
    </div>
  );
};

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { routesPaths } from '../../../../navigation';
import { EventSingle } from '../../../../services';
import { useGetCurrentUserInfoQuery } from '../../../../services/currentUserApi';
import {
  useGetEventChatLinkQuery,
  useJoinEventMutation,
  useLeaveEventMutation,
  useUpdateReviewMutation,
  useWriteReviewMutation
} from '../../../../services/eventsService';
import { eventCreationPrefillActions } from '../../../../state/slices/eventCreationPrefillSlice';
import {
  canUserJoinEvent,
  getEventButtonText,
  getEventCreationInitialValues,
  getEventIdFromSignature,
  getEventUrl
} from '../../../../utils';
import { Button, TextArea } from '../../../controls';
import { ButtonWithConfirmation } from '../../../ui';
import '../EventScreen.sass';
import { EventJoinModal } from './EventJoinModal';

export interface EventActionsProps {
  event: EventSingle;
  isAuthor: boolean;
  isMember: boolean;
  eventPassed: boolean;
  openByocJoin: () => void;
}

export const EventActions = ( {
  event,
  eventPassed,
  isAuthor,
  isMember,
  openByocJoin
}: EventActionsProps ) => {
  const navigate = useNavigate();
  const { eventSignature } = useParams();
  const dispatch = useDispatch();

  const eventId = useMemo( () => {
    if ( !eventSignature ) throw new Error( 'no event signature' );
    return getEventIdFromSignature( eventSignature );
  }, [ eventSignature ] );

  const { data: currentUser } = useGetCurrentUserInfoQuery();
  const { data: chatLink } = useGetEventChatLinkQuery( isMember ? event._id : skipToken );
  const [ writeReview ] = useWriteReviewMutation();
  const [ updateReview ] = useUpdateReviewMutation();
  const [ leaveEvent ] = useLeaveEventMutation();
  const [ joinEvent ] = useJoinEventMutation();

  const currentUserReview = useMemo( () => {
    return event.reviews.find( review => review.author._id === currentUser?._id );
  }, [ event, currentUser ] );

  const [ isJoinModalOpen, setJoinModalOpen ] = useState( false );
  const [ reviewText, setReviewText ] = useState( currentUserReview?.text || '' );
  const [ isEditingReview, setEditingReview ] = useState( false );

  const toggleEditingReview = ( value: boolean ) => () => setEditingReview( value );

  const joinButtonText = useMemo(
    () => getEventButtonText( event, currentUser ),
    [ event, currentUser ]
  );

  const canJoin = useMemo(
    () => canUserJoinEvent( event, currentUser ),
    [ event, currentUser ]
  );

  const onJoin = useMemo( () => {
    if ( canJoin ) {
      if ( event.byoc ) {
        return () => openByocJoin();
      } else {
        return () => {
          joinEvent( event._id ).then( () => setJoinModalOpen( true ) );
        };
      }
    }
  }, [ canJoin, event ] );

  const hasPostedReview = !!currentUserReview;

  const goEdit = () => navigate( routesPaths.event.single.edit );
  const goInvite = () =>
    navigate( [ getEventUrl( event ), routesPaths.event.single.invite ].join( '/' ) );
  const goDuplicate = () => {
    dispatch(
      eventCreationPrefillActions.setPrefill( getEventCreationInitialValues( event, true ) )
    );
    navigate( [ routesPaths.event.index, routesPaths.event.creation ].join( '/' ) );
  };

  const onPostReview = () => {
    if ( reviewText.length && ( !hasPostedReview || isEditingReview ) && eventId ) {
      const mutation = isEditingReview ? updateReview : writeReview;
      mutation( { id: eventId, text: reviewText } )
        .unwrap()
        .then( () => {
          setEditingReview( false );
        } );
    }
  };

  const onLeaveConfirm = () => {
    if ( eventId )
      leaveEvent( eventId )
        .unwrap()
        .then( () => navigate( -1 ) );
  };

  const canLeave = useMemo( () => {
    if ( event.byoc ) {
      if ( !currentUser ) return false;
      return event.byoc.joinedCrew?.author._id === currentUser._id;
    } else {
      return isMember && !isAuthor && !eventPassed;
    }
  }, [ isMember, isAuthor, eventPassed, event, currentUser ] );

  console.log( { canJoin } );

  return (
    <>
      {eventPassed && ( isAuthor || isMember ) && (
        <section className='event-post-review'>
          {( !hasPostedReview || isEditingReview ) && (
            <>
              <h1>Tell us how much you loved it!</h1>
              <TextArea
                name='review'
                onChange={setReviewText}
                value={reviewText}
                maxCharacters={300}
                placeholder='I really liked the event! Daryl and Jessica are super nice and the spot was really chill. We hung out after as well :)'
                rows={2}
                className='review-textarea'
              />
            </>
          )}
          <Button
            text={hasPostedReview && !isEditingReview ? 'Edit Review' : 'Post a Review'}
            onClick={
              hasPostedReview && !isEditingReview
                ? toggleEditingReview( true )
                : onPostReview
            }
            styles={{ marginTop: 16, marginBottom: 16 }}
            disabled={hasPostedReview && !isEditingReview ? false : !reviewText}
          />
        </section>
      )}
      {eventPassed && (
        <>
          <Button
            text='Duplicate event'
            styles={{ marginBottom: 12, marginTop: 12 }}
            onClick={goDuplicate}
            style={'secondary'}
          />
        </>
      )}
      {isAuthor && (
        <>
          {!eventPassed && (
            <>
              <Button
                text='Edit event'
                styles={{ marginBottom: 12, marginTop: 24 }}
                onClick={goEdit}
              />
              <Button
                text='Invite to event'
                style='secondary'
                onClick={goInvite}
              />
            </>
          )}
        </>
      )}
      {!isMember && !eventPassed && (
        <>
          <Button
            text={joinButtonText}
            disabled={!canJoin}
            onClick={onJoin}
            styles={{ marginTop: 16 }}
          />
        </>
      )}
      <EventJoinModal
        isOpen={isJoinModalOpen}
        onClose={() => setJoinModalOpen( false )}
      />
      {event.byoc && isMember && !isAuthor && (
        <a
          target='_blank'
          href={chatLink?.regular}
          rel='noreferrer'
          className='button'
          style={{ textAlign: 'center', marginTop: 40 }}
        >
          Message creator
        </a>
      )}
      {canLeave && (
        <ButtonWithConfirmation
          buttonText='Leave event'
          modalText={
            !event.byoc ? (
              <>
                The event creator will be
                <br />
                sad to see you go :(
              </>
            ) : (
              <>
                If you leave, all members from <br /> your crew will be removed
              </>
            )
          }
          modalTitle='Leave event?'
          buttonProps={{
            style: 'transparentNoBorder',
            styles: { marginTop: 16 }
          }}
          cancel={{ label: 'No, I’ll stay' }}
          confirm={{
            label: !event.byoc ? 'Yes, I want to leave' : 'Yes, we want to leave',
            onClick: onLeaveConfirm
          }}
        />
      )}
    </>
  );
};

import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routesPaths } from '../../../navigation';
import { GoogleRegisterData, authService, registrationService } from '../../../services';
import { useTypedSelector } from '../../../state';
import { authActions } from '../../../state/slices/authSlice';
import { otpActions } from '../../../state/slices/otpService';
import { googleActions } from '../../../state/slices/thirdParty';
import { googleRegistrationValidationSchema } from '../../../utils';
import {
  CheckBoxField,
  DropDownField,
  OTPField,
  PhoneField,
  Submit,
  TextField
} from '../../forms';
import { ScreenTitle } from '../../ui';

export const GoogleRegisterScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const otpState = useTypedSelector( state => state.otp );
  const googleState = useTypedSelector( state => state.googleSlice );

  const [ otpSent, setOTPSent ] = useState( false );

  if ( !googleState.code ) {
    navigate( routesPaths.onboarding );
  }

  useEffect( () => {
    if ( otpState.error && typeof otpState.error === 'string' ) {
      toast.error( otpState.error );
      dispatch( otpActions.setError( undefined ) );
    }
    if ( otpSent && otpState.success ) {
      toast.success( 'One time password has been sent to your phone number' );
      dispatch( otpActions.setSuccess( undefined ) );
    }
  }, [ otpState.error, otpSent, otpState.success ] );

  useEffect( () => {
    if ( googleState.registrationSuccess ) {
      toast.success( 'Registered successfully' );
      dispatch( authActions.setFirstLogin( true ) );
    } else if ( googleState.registrationSuccess === false ) {
      toast.error( 'Some error occurred' );
    }
    dispatch( googleActions.setRegistrationSuccess( undefined ) );
  }, [ googleState.registrationSuccess ] );

  const initialValues = {
    code: googleState.code,
    age: 18,
    emailMarketing: false,
    gender: '',
    otp: '',
    phone: '',
    termsAgreed: false,
    googleRedirectUri: window.location.origin,
    overrideName: ''
  } as GoogleRegisterData;

  const sendOTP = async ( phone: string ) => {
    const sent = await registrationService.sendOTP( phone, 'register' );
    setOTPSent( !!sent );
  };

  return (
    <>
      <ScreenTitle title='Sign Up' />
      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          registrationService.registerWithGoogle( values ).then( response => {
            if ( response ) authService.processTokensPair( response );
          } );
        }}
        validationSchema={googleRegistrationValidationSchema}
        validateOnMount
      >
        {( { values, errors, handleSubmit } ) => (
          <Form onSubmit={handleSubmit}>
            <TextField
              name='overrideName'
              title='Name'
              placeholder='Name'
            />
            <TextField
              name='age'
              title='Age'
              placeholder='Age'
              type='number'
              min={18}
              max={120}
            />
            <DropDownField
              name='gender'
              title='Gender'
              placeholder='Gender'
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'Other', value: 'other' }
              ]}
            />
            <PhoneField
              name='phone'
              title='Phone Number'
              action={
                !errors.phone && !otpSent
                  ? {
                      label: 'Send OTP',
                      onClick: sendOTP
                    }
                  : undefined
              }
            />
            <OTPField
              name='otp'
              title='Fill your OTP code below'
              action={
                !errors.phone && otpSent
                  ? {
                      label: 'Did not get a code?',
                      onClick: sendOTP.bind( null, values.phone ),
                      buttonText: 'Resend OTP',
                      lastSend: DateTime.now().plus( { seconds: 60 } )
                    }
                  : undefined
              }
              disabled={!otpSent}
            />
            <CheckBoxField
              name='termsAgreed'
              label={
                <p>
                  I agree to Crewww’s{' '}
                  <a
                    href={routesPaths.terms}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Terms and Conditions
                  </a>
                  , and agree to being over 18 years of age
                </p>
              }
            />
            <CheckBoxField
              name='emailMarketing'
              label='I want to receive email marketing communications from Crewww'
            />
            <Submit text='Submit' />
          </Form>
        )}
      </Formik>
    </>
  );
};

import { Formik } from 'formik';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../navigation';
import { authService, registrationService } from '../../services';
import { useTypedSelector } from '../../state';
import { authActions } from '../../state/slices/authSlice';
import { otpActions } from '../../state/slices/otpService';
import {
  getRestorePasswordInitialValues,
  restorePasswordValidationSchema
} from '../../utils';
import { OTPField, PasswordField, PhoneField, Submit } from '../forms';
import { ScreenTitle } from '../ui';

const initialValues = getRestorePasswordInitialValues();

export const RestorePasswordScreen = () => {
  const otpState = useTypedSelector( state => state.otp );
  const authState = useTypedSelector( state => state.auth );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ otpSent, setOTPSent ] = useState( false );

  const sendOTP = async ( phone: string ) => {
    const sent = await registrationService.sendOTP( phone, 'restore' );
    setOTPSent( !!sent );
  };

  useEffect( () => {
    if ( otpState.error && typeof otpState.error === 'string' ) {
      toast.error( otpState.error );
      dispatch( otpActions.setError( undefined ) );
    }
    if ( otpSent && otpState.success ) {
      toast.success( 'One time password has been sent to your phone number' );
      dispatch( otpActions.setSuccess( undefined ) );
    }
  }, [ otpState.error, otpSent, otpState.success ] );

  useEffect( () => {
    if ( authState.restorePasswordSuccess ) {
      toast.success( 'Password restored successfully' );
      navigate( routes.auth.login.path );
      dispatch( authActions.setRestorePasswordError( undefined ) );
      dispatch( authActions.setRestorePasswordSuccess( undefined ) );
    } else if ( authState.restorePasswordSuccess === false ) {
      toast.error( 'Failed to restore password' );
    }
  }, [ authState.restorePasswordSuccess ] );

  return (
    <>
      <ScreenTitle title='Restore password' />
      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          authService.restorePassword( values.phone, values.newPassword, values.otp );
        }}
        validationSchema={restorePasswordValidationSchema}
        validateOnMount={true}
      >
        {( { errors, handleSubmit, values } ) => (
          <form onSubmit={handleSubmit}>
            <PhoneField
              name='phone'
              title={'Phone'}
              action={
                !errors.phone && !otpSent
                  ? {
                      label: 'Send OTP',
                      onClick: sendOTP
                    }
                  : undefined
              }
            />
            {otpSent && (
              <OTPField
                name='otp'
                title='Fill your OTP code below'
                action={
                  !errors.phone && otpSent
                    ? {
                        label: 'Did not get a code?',
                        onClick: sendOTP.bind( null, values.phone ),
                        buttonText: 'Resend OTP',
                        lastSend: DateTime.now().plus( { seconds: 60 } )
                      }
                    : undefined
                }
                disabled={!otpSent}
              />
            )}
            {!errors.otp && (
              <>
                <PasswordField
                  name='newPassword'
                  placeholder='New password'
                />
                <PasswordField
                  name='confirmPassword'
                  placeholder='Confirm your password'
                />
                <Submit text='Restore password' />
              </>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};
